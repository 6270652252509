// NavigationBar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NavigationBar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import logo from './BookNow_transparent.png'; // Adjust the path as necessary

const NavigationBar = () => {
  const navigate = useNavigate();

  return (
    <nav className={styles.navBar}>
      <div className={styles.booknow} onClick={() => navigate('/')}>
        <img src={logo} alt="BookNow Logo" className={styles.logo} />
      </div>
      <ul className={styles.navList}>
        <li className={styles.navItem} onClick={() => navigate('/home')}>Home</li>
        <li className={styles.navItem} onClick={() => navigate('/about')}>About</li>
        <li className={styles.navItem} onClick={() => navigate('/profile')}>
          <FontAwesomeIcon icon={faUser} /> 
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;
