import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './Payment.css';
import { db } from './firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const { selectedSeats, totalAmount } = location.state;
  const [isProcessing, setIsProcessing] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const { movieId } = useParams();
  const functions = getFunctions();

  // Update form validity state whenever name or email changes
  useEffect(() => {
    setFormValid(
      name.trim() !== '' &&
      email.trim() !== '' &&
      email.includes('@') && // Simple email validation
      selectedSeats.length > 0
    );
  }, [name, email, selectedSeats]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements || !formValid) {
      console.log('Stripe.js has not yet loaded or form is not valid.');
      return;
    }

    setIsProcessing(true); // Indicate that payment is being processed

    try {
      const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
      const paymentIntentResult = await createPaymentIntent({
        amount: totalAmount * 100, // Stripe requires amount in cents
        seats: selectedSeats.map(seatId => ({
          seatId,
          email
        })),
        currency: 'eur'
      });

      const clientSecret = paymentIntentResult.data.clientSecret;
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: { email, name },
        },
      });

      if (paymentResult.error) {
        console.error('Payment error:', paymentResult.error);
        navigate('/error');
        return;
      }

      // Update each seat as booked in Firestore
      await Promise.all(selectedSeats.map(seatId => {
        const seatRef = doc(db, 'Movies', movieId, 'seats', seatId);
        return updateDoc(seatRef, {
          occupied: true,
          bookedBy: email,
          price: totalAmount
        });
      }));

      navigate('/confirmation', { state: { selectedSeats, totalAmount, email } });
    } catch (error) {
      console.error('Error during payment process:', error);
      navigate('/error');
    } finally {
      setIsProcessing(false); // Reset processing state
    }
  };

  return (
    <div className="form-card">
      <form onSubmit={handleSubmit}>
        <h1>Card Payment</h1>
        <div className="personal-details">
          <label style={{ color: "#555", marginLeft: "15px", fontFamily: "Arial", fontSize: "13px", fontWeight: "bold", lineHeight: "1.5" }}>
            Personal Details:
          </label>
        </div>
        <input type="text" placeholder="Full name" value={name} onChange={e => setName(e.target.value)} />
        <input type="email" placeholder="Enter your email" value={email} onChange={e => setEmail(e.target.value)} />
        <div className="card-elements">
          <CardInput label="Card Number" element={CardNumberElement} />
          <CardInput label="Expiry Date" element={CardExpiryElement} />
          <CardInput label="CVV2" element={CardCvcElement} />
        </div>
        <button type="submit" disabled={!stripe || isProcessing || !formValid}>
          {isProcessing ? 'Processing...' : `Pay EUR ${totalAmount}`}
        </button>
      </form>
    </div>
  );
};

const CardInput = ({ label, element: ElementComponent }) => (
  <div className="card-element">
    <label>{label}</label>
    <ElementComponent options={{
      style: {
        base: {
          color: "#ffffff",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#555"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
    }} />
  </div>
);

export default Payment;
