import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import NavigationBar from './HomePageComponents/NavigationBar';
import Footer from './HomePageComponents/Footer';
import styles from './HomePage.module.css';

const HomePage = () => {
  const [movies, setMovies] = useState([]);
  const [visibleLetters, setVisibleLetters] = useState(0);
  const navigate = useNavigate();
  const welcomeText = "Welcome to BookNow!";
  const animatedLetters = welcomeText.split("");

  useEffect(() => {
    let timer = setInterval(() => {
      setVisibleLetters((prev) => prev + 1);
      if (visibleLetters >= welcomeText.length) {
        clearInterval(timer);
      }
    }, 100);
    return () => clearInterval(timer);
  }, [visibleLetters, welcomeText.length]);

  useEffect(() => {
    const fetchMovies = async () => {
      const querySnapshot = await getDocs(collection(db, "Movies"));
      const moviesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMovies(moviesData);
    };

    fetchMovies();
  }, []);

  // Popcorn effect initialization
  useEffect(() => {
    const MAX_POPCORN = 1; // Number of popcorn pieces
    const popcornContainer = document.getElementById('popcorn-container');
    for (let i = 0; i < MAX_POPCORN; i++) {
      const popcorn = document.createElement('div');
      popcorn.className = styles.popcorn;
      popcorn.style.left = `${Math.random() * 100}vw`;
      popcorn.style.animationDuration = `${Math.random() * 5 + 5}s`;
      popcorn.style.animationDelay = `${Math.random() * 5}s`;
      popcornContainer.appendChild(popcorn);
    }
  }, []);

  return (
    <>
      <NavigationBar />
      <div className={styles.homepage}>
        <div id="popcorn-container" className={styles.popcornContainer}></div>
        <h1 className={styles.welcomeHeading}>
          {animatedLetters.map((char, index) => (
            <span key={index} style={{ opacity: index < visibleLetters ? 1 : 0 }}>{char}</span>
          ))}
        </h1>
        <h4 className={styles.h4}>Upcoming Movies</h4>
        <ul className={styles.movieList}>
          {movies.map(movie => (
            <li key={movie.id} className={styles.movieItem} onClick={() => navigate(`/movie/${movie.id}`)}>
              <img src={movie.posterUrl} alt={`${movie.title} Poster`} className={styles.moviePoster} />
              <div className={styles.movieDetails}>
                <h2 className={styles.movieTitle}>{movie.title}</h2>
                <p className={styles.movieVenue}>Venue: {movie.venue}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
