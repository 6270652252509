import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import MovieCard from './components/MovieCard';
import SeatSelection from './components/SeatSelection';
import Payment from './components/Payment';
import Confirmation from './components/Confirmation';
import PaymentError from './components/PaymentError';
import { Elements } from '@stripe/react-stripe-js';

export const RoutesConfig = ({ movieId, stripePromise }) => (
  <>
    <Route path="/" element={<HomePage />} />
    <Route path="/movie/:movieId" element={<MovieCard />} />
    <Route path="/movie/:movieId/seat-selection" element={<SeatSelection />} />
    <Route path="/movie/:movieId/payment" element={
      <Elements stripe={stripePromise}>
        <Payment />
      </Elements>
    } />
    <Route path="/confirmation" element={<Confirmation />} />
    <Route path="/error" element={<PaymentError />} />
  </>
);