// Footer.js
import React from 'react';
import styles from'./Footer.module.css'; // Assume you have a corresponding CSS file

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>© 2024 BookNow. All rights reserved.</p>
      <p>Terms & Conditions | Privacy Policy</p>
    </footer>
  );
};

export default Footer;
