import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MovieCard.module.css';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';


const MovieCard = () => {
  const { movieId } = useParams();
  const [movie, setMovie] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Movie ID received:", movieId); // Check if movieId is logged correctly
  
    if (movieId) {
      const docRef = doc(db, 'Movies', movieId);
      getDoc(docRef).then(docSnap => {
        if (docSnap.exists()) {
          setMovie(docSnap.data());
        } else {
          console.error('No such document!');
        }
      }).catch(error => console.error('Error fetching movie data:', error));
    }
  }, [movieId]);

  if (!movie) return <div className="MovieCard">Loading movie data...</div>;
  const { title, description, posterUrl, venue, time } = movie;

  return (
    <div className={styles.MovieCard}>
    <div 
      className={styles.backgroundImg}
      style={{ backgroundImage: `url(${posterUrl})` }}
    >
      <div className={styles.imgGradient}></div>
      <div className={styles.MovieCard_content}>
        <h1 className={styles.MovieCard_title}>{title}</h1>
        <p className={styles.MovieCard_description}>{description}</p>
        <div className={styles.MovieCard_eventDetails}>
          <p><strong>Venue:</strong> {venue}</p>
          <p><strong>Time:</strong> {time}</p>
        </div>
        <button 
          className={styles.MovieCard_button}
          onClick={() => navigate(`/movie/${movieId}/seat-selection`, { state: { movieId } })}
        >
          Book Now
        </button>
      </div>
    </div>
  </div>
  );
};

export default MovieCard;
