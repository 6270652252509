// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAfkMNhcQNBNdGJZhTrKjhFJX5MzyGro7A",
  authDomain: "bookknoww.firebaseapp.com",
  databaseURL: "https://bookknoww-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bookknoww",
  storageBucket: "bookknoww.appspot.com",
  messagingSenderId: "308988440282",
  appId: "1:308988440282:web:e84f736e472efb533e3fe3"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firestore instance
const db = getFirestore(app);

// Optional: Get Firebase Auth instance if you're using Firebase Authentication
const auth = getAuth(app);

console.log("Firestore instance:", db);

export { db, auth };