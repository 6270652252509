import React, { useEffect, useState } from 'react';
import './SeatSelection.css';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const seatPrice = 12;  // Price per seat in EUR




const SeatSelection = () => {
  const [seats, setSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const navigate = useNavigate();
  const { movieId } = useParams();  // This is hardcoded, replace with dynamic data if needed

  useEffect(() => {
    const fetchSeats = async () => {
      const docRef = doc(db, 'Movies', movieId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const seatsCollectionRef = collection(docRef, 'seats');
        const seatsSnapshot = await getDocs(seatsCollectionRef);

        if (!seatsSnapshot.empty) {
          const loadedSeats = seatsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          console.log("Loaded seats:", loadedSeats); // Check what seats are loaded
          setSeats(loadedSeats);
        } else {
          console.log('No seats found in the subcollection');
        }
      } else {
        console.log('No such document!');
      }
    };

    fetchSeats();
  }, [movieId]);


  function handleSelectedState(seatId, isOccupied) {
    if (isOccupied) {
      console.log('Attempted to select an occupied seat.');
      return; // Early return to stop the function if the seat is occupied
    }

    if (selectedSeats.includes(seatId)) {
      setSelectedSeats(selectedSeats.filter(id => id !== seatId));
    } else {
      setSelectedSeats([...selectedSeats, seatId]);
    }
  }

  const handleProceedToPayment = () => {
    const totalAmount = selectedSeats.length * seatPrice;
    navigate('/movie/movieId/payment', { state: { selectedSeats, totalAmount,movieId } });
  };

  return (
    <div className="App">
      <ShowCase />
      <Cinema
        seats={seats}
        selectedSeats={selectedSeats}
        onSelectedSeatsChange={handleSelectedState}
      />
      <p className="info">
         <span className="count">{selectedSeats.length}</span>  <span className="total">EUR{selectedSeats.length * seatPrice}</span>
      </p>
      <button onClick={handleProceedToPayment} disabled={selectedSeats.length === 0} className="proceed-button">
        Pay
      </button>
    </div>
  );
};

function ShowCase() {
  return (
    <ul className="ShowCase">
      <li><span className="seat" /> <small>Available</small></li>
      <li><span className="seat selected" /> <small>Selected</small></li>
      <li><span className="seat occupied" /> <small>Occupied</small></li>
    </ul>
  );
}

function Cinema({ seats, selectedSeats, onSelectedSeatsChange }) {
  if (!seats.length) {
    return <p>No seats data available.</p>;
  }

  // Group seats by row and prepare for rendering
  const seatsByRow = seats.reduce((acc, seat) => {
    // Initialize row in accumulator if it doesn't exist
    acc[seat.row] = acc[seat.row] || [];
    if (seat.id && seat.seatNumber) {
      acc[seat.row].push(seat);
    } else {
      // Push a placeholder for an empty seat
      acc[seat.row].push({ isEmpty: true });
    }
    return acc;
  }, {});

  // Sort each row's seats by seat number where possible
  Object.keys(seatsByRow).forEach(row => {
    seatsByRow[row].sort((a, b) => {
      return a.seatNumber && b.seatNumber ? parseInt(a.seatNumber) - parseInt(b.seatNumber) : 0;
    });
  });

  return (
    <div className="Cinema">
      <div className="screen" /> {/* Simulates the movie screen */}
      {Object.keys(seatsByRow).map(row => (
        <div key={row} className="row">
          {seatsByRow[row].map((seat, index) => (
            seat.isEmpty ? (
              <span key={`empty-${row}-${index}`} className="seat ghost" aria-hidden="true" /> // Ghost seat
            ) : (
              <span
                tabIndex={seat.occupied ? -1 : 0}
                key={seat.id}
                className={clsx('seat', {
                  'selected': selectedSeats.includes(seat.id),
                  'occupied': seat.occupied
                })}
                onClick={() => !seat.occupied && onSelectedSeatsChange(seat.id)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !seat.occupied) {
                    onSelectedSeatsChange(seat.id);
                  }
                }}
              >
                {selectedSeats.includes(seat.id) && seat.seatNumber} {/* Render seat number only when selected */}
              </span>
            )
          ))}
        </div>
      ))}
    </div>
  );
}




export default SeatSelection;
