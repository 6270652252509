import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import QRCode from 'qrcode.react'; // Import the QRCode component
import styles from './Confirmation.module.css';

const Confirmation = () => {
  const location = useLocation();
  const { selectedSeats, totalAmount, email } = location.state;

  const [movieTitle, setMovieTitle] = useState('');
  const [posterUrl, setPosterUrl] = useState('');
  const [venue, setVenue] = useState('');
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState('');

  // Hardcoded movie ID
  const movieId = "movieId"; // Ensure this is the actual ID you intend to query

  useEffect(() => {
    const fetchMovieDetails = async () => {
      const docRef = doc(db, 'Movies', movieId); // Use the hardcoded movieId
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setMovieTitle(data.title);
          setPosterUrl(data.posterUrl); // Assuming 'posterUrl' is the field name in your Firestore document
          setVenue(data.venue); // Assuming 'venue' is the field name in your Firestore document
          setTime(data.time);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
      setLoading(false);
    };

    fetchMovieDetails();
  }, []); // Empty dependency array since movieId is hardcoded

  if (loading) {
    return <div>Loading movie details...</div>;
  }

  // Generate a random booking reference number for the QR code
  const bookingReference = Math.random().toString(36).substr(2, 9);

  return (
    <div>
      <div className={styles.container}>
        <h1 className={styles.text}>Booking Confirmation</h1>
        {/* Add the new image here */}
        <img src="https://www.dropbox.com/scl/fi/e76uhb1egy3qqvdxbapez/poster-2.jpg?rlkey=gob37o21l3x18vmnhlh4y03qu&st=h29wtfw8&raw=1" alt="Your Image Description" className={styles.yourImageClass} />
        <h3 className={styles.title}>{movieTitle || 'Unavailable'}</h3>
        <p className={styles.venue}>
          {venue && <img src="https://www.dropbox.com/scl/fi/tczu6wrnw92evvlvo0umo/location-indicator.png?rlkey=votyc07821j6fwhc77v7guysp&st=e5d5fj35&raw=1" alt="Location Icon" className={styles.locationIcon} />}
          {venue || 'Venue information not available'}
        </p>
        <p className={styles.time}>
          {time && <img src="https://www.dropbox.com/scl/fi/fimh2mwtk8476p55ywaad/m2H7i8H7m2b1G6i8.png?rlkey=i2yufpr2dnu4s4sqd40pm4r2k&st=i0shcz39&raw=1" alt="Time Icon" className={styles.timesIcon} />}
          {time || 'Time information not available'}  
        </p>
        <p className={`${styles.seats}`}>Seats: {selectedSeats.join(", ")}</p>
        <p className={`${styles.totalamount}`}>Total Amount: €{totalAmount.toFixed(2)}</p>
        <p className={`${styles.email}`}>Email: {email}</p>
        {/* Render the QR code with the booking reference */}
        <QRCode value={bookingReference} className={styles.qrcode} />
        {/* Add the success message under the QR code */}
        <p className={styles.successMessage}>Your payment completed successfully <br />and your ticket has been sent to your email.</p>  
        <Link to="/" className={styles.link}>Return to Home</Link>
      </div>
    </div>
  );
  
  
};

export default Confirmation;
