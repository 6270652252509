import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';

import { RoutesConfig } from './RoutesConfig';


const stripePromise = loadStripe('pk_test_51P5ERQGnoU0w77yKq500RSfdF7ZKWHlM7Jb1ubHMYHe0j2P78Gf9emDEgmAW6ce7z4RPWCNcFfBf2879OY3xoQvz00li5e4zLL'); // Replace with your Stripe public key

function App() {
  const [movieId, setMovieId] = useState(null);



useEffect(() => {
  setMovieId('movieId'); // Replace 'known_good_movieId' with a valid ID from your Firestore
}, []);

  if (!movieId) {
    return <div>Loading movie ID...</div>; // Show a loading message until movieId is fetched
  }

  return (
    <Router>
      <Routes>
        {RoutesConfig({ movieId, stripePromise })}
      </Routes>
    </Router>
  );
}

export default App;
